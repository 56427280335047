import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Check } from 'react-feather';

const Checkbox = ({
    id,
    name,
    className,
    handleChange,
    isSelected,
    checkBoxLabel,
    hideLabel,
    checkBoxDisable,
    isError,
    labelPosition,
    labelClassName,
    register = () => { },
    height,
    width,
    errorMessage,
    checkClassName,
    checkIconClass,
    checkBoxContainerClassName,
    onKeyDown
}) => {
    const containerClassNames = classnames(
        'flex items-center cursor-pointer relative',
        labelPosition === 'left' && 'flex-row-reverse',
        checkBoxDisable && 'pointer-events-none',
        className,
        checkBoxContainerClassName
    );
    const handleKeyDown = (event) => {
        if (event.key === ' ' || event.key === 'Spacebar') {
            event.preventDefault();

            // Programmatically click the hidden checkbox
            const checkbox = document.getElementById(id);
            if (checkbox) {
                checkbox.click();
            }
        }
        if (onKeyDown) {
            onKeyDown(event);
        }
    };

    return (
        <>
            <label className={containerClassNames} tabIndex={0} onKeyDown={handleKeyDown} >
                <span
                    className={`flex justify-center items-center relative rounded border ${isSelected ? 'bg-primary-300 border-primary-300' : 'bg-transparent'
                    } ${checkClassName} w-${width || '4'} h-${height || '4'}`}
                >
                    {isSelected && <Check className={checkIconClass} size={16} />}
                </span>

                <input
                    id={id}
                    name={name}
                    onChange={handleChange}
                    type="checkbox"
                    checked={isSelected}
                    {...register(name)}
                    className={`hidden ${className}`}
                />

                {!hideLabel && (
                    <span
                        className={`text-sm text-gray-100 select-none ${labelClassName} ${labelPosition === 'left' ? 'mr-2' : 'ml-2'}`}>
                        {checkBoxLabel}
                    </span>
                )}
            </label>
            {isError && <div className="block text-xs text-error-300 mt-1">{errorMessage}</div>}
        </>
    );
};

Checkbox.defaultProps = {
    labelPosition: 'right'
};

Checkbox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    handleChange: PropTypes.func,
    isSelected: PropTypes.bool,
    isError: PropTypes.bool,
    value: PropTypes.string,
    checkBoxLabel: PropTypes.string,
    hideLabel: PropTypes.bool,
    checkBoxDisable: PropTypes.bool,
    labelPosition: PropTypes.oneOf(['left', 'right']),
    labelClassName: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    errorMessage: PropTypes.string
};

export default Checkbox;
